import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import { useMatomo } from '@datapunt/matomo-tracker-react';

import L from './util/L10n';
import DetectLocale from './util/DetectLocale';
import CookieAccess from './util/CookieAccess';
import PageHelmet from './util/PageHelmet';
import CanonicalUrl from './util/CanonicalUrl';

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    h3: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
    h4: {
        marginBlockStart: '1em',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
    },
    ul: {
        textAlign: 'left',
        hyphens: 'auto',
    },
    address: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        fontWeight: 500,
        textAlign: 'center',
    },
    email: {
        fontWeight: 500,
    },
    rightsList: {
        paddingLeft: '4em',
        textAlign: 'justify',
        hyphens: 'auto',
    },
    rightsItem: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
    table: {
        border: 'solid 1px black',
        borderCollapse: 'collapse',
    },
    tableHeaderRow: {

    },
    tableRow: {

    },
    tableHeader: {
        border: 'solid 1px black',
        textAlign: 'center',
        padding: '0 6px',
    },
    tableData: {
        border: 'solid 1px black',
        textAlign: 'center',
        padding: '0 6px',
    },
    tableDataText: {
        border: 'solid 1px black',
        textAlign: 'left',
        padding: '0 6px',
    },
    button: {

    },
    buttonLabel: {

    },
});

class DataprotectionPage extends PureComponent {
    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
    }

    buildMetaData() {
        const canonicalUrl = CanonicalUrl.get('/datenschutz');
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { canonicalUrl, ogLocale, ogType };
    }

    render() {
        const { classes, userLang, documentTitle, clearConsent } = this.props;
        // eslint-disable-next-line
        const lang = userLang ? userLang : DetectLocale.getUserLanguage();
        const {
            description, canonicalUrl, ogLocale, ogType,
        } = this.buildMetaData();
        const pageHelmet =
            <PageHelmet
                documentTitle={documentTitle}
                metaDescription={description}
                canonicalUrl={canonicalUrl}
                ogTitle={documentTitle}
                ogDescription={description}
                ogUrl={canonicalUrl}
                ogLocale={ogLocale}
                ogType={ogType}
            />;
        return (
            <Box lang='de'>
                {pageHelmet}
                <Typography variant='h4' component='h2' className={classes.h2}>
                    Datenschutzerklärung
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Für die Datenverarbeitung verantwortlich
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Für die Datenverarbeitung in Bezug auf diese Website (nachfolgend: die Webseite) sind wir, die<br />
                    Fahrgastmedien Hannover GmbH, Goethestraße 13 a, 30169 Hannover, <a href="mailto:info@fahrgastfernsehen.city">info@fahrgastfernsehen.city</a><br />
                    Vertreten durch die Geschäftsführer:<br />
                    Bernd Hoedtke, René Schweimler<br />
                    Goethestraße 13a<br />
                    30169 Hannover<br />
                    Registergericht: Amtsgericht Hannover<br />
                    Registernummer: HRB 219796<br />
                    Umsatzsteuer-Identifikationsnummer: DE 329876966<br />
                    datenschutzrechtlich verantwortlich.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Daten, die wir automatisch verarbeiten
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Sie können unsere Webseite besuchen, ohne dabei viele personenbezogene Daten preiszugeben. Wir verarbeiten aber jedes Mal, wenn Sie unsere Seite besichern, automatisch einige Daten, die Ihr Browser an unseren Server übermittelt (z.B. die IP-Adresse, abgerufene Inhalte, Datum und Uhrzeit des Abrufs, Browsertyp, Version und Betriebssystem und ggf. die zuvor besuchte Seite). Die Daten benötigen wir unter anderem, um eine richtige Darstellung der Webseite, sowie die Stabilität und Sicherheit derselben zu gewährleisten. Mit diesen Daten können wir Nutzer jedoch nicht direkt identifizieren.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Daten, die uns freiwillig übermittelt werden
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Darüber hinaus werden einige persönliche Angaben erst erhoben, wenn Sie an besonderen Aktionen teilnehmen oder Sie freiwillig mit uns Kontakt aufnehmen.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Personenbezogene Daten werden etwa verarbeitet, wenn folgende Aktionen ausgeführt werden:
                </Typography>
                <Typography variant='body1' component='ul' className={classes.ul}>
                    <li>Teilnahme an Gewinnspielen</li>
                    <li>Teilnahme an Mitmach-Aktionen</li>
                    <li>Teilnahme an Deals</li>
                    <li>Kontaktaufnahme per E-Mail</li>
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wenn Sie uns aus oben genannten Gründen personenbezogene Daten zur Verfügung stellen, nutzen wir diese zur Abwicklung der Anfragen, Gewinnspiele, Aktionen und Deals. Wir nutzen die Daten auf Basis Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO) und unserem berechtigten Interesse daran, Daten die uns von Ihnen zu diesen Zwecken übermittelt werden, in diesem Zusammenhang nutzen zu können (Art. 6 Abs. 1 Buchst. f DSGVO).
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Außerdem nutzen wir Daten in pseudonymisierter Form zur statistischen Auswertung und Analyse der Leistung unseres Angebotes, wenn Sie dieser Verarbeitung zustimmen (Art. 6 Abs. 1 Buchst. a DSGVO).  Mit solchen Nutzungsdaten können wir zum Beispiel feststellen, wie viele Nutzer gleichzeitig auf die Webseite zugreifen, welche Funktionen sie nutzen und welche Bereiche und Funktionen unserer Webseite besonders beliebt sind. Da die Daten pseudonymisiert verarbeitet werden, können unter normalen Umständen keine Rückschlüsse auf eine identifizierbare Person gezogen werden. Für diese Zwecke setzen wir Cookies ein.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir arbeiten teilweise mit Partnern zusammen, die in unserem Auftrag Daten für uns verarbeiten (Auftragsverarbeiter). Diese Partner verarbeiten diese Daten innerhalb der Europäischen Union.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Rechte der Nutzer
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Gerne informieren wir Sie kostenfrei über die bei uns zu Ihnen gespeicherten Daten und löschen oder berichtigen diese, wenn Sie die dies wünschen und keine gesetzlichen Aufbewahrungspflichten einer Löschung entgegenstehen. Sie können der Datenverarbeitung durch uns grundsätzlich widersprechen. Sie können sich mit ihren Anliegen jederzeit bei uns melden. Im Folgenden informieren wir ganz genau über alle Rechte.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir stellen selbstverständlich sicher, dass Sie alle Rechte wirksam nutzen können.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Sollten Sie der Meinung sein, dass ihre Daten unzulässig verarbeitet wurden, können sie sich selbstverständlich auch bei der zuständigen Aufsichtsbehörde beschweren.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    1. Allgemeine Informationen zur Datenverarbeitung
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    1.1 Wichtige Begriffe
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Dieser Begriff umfasst praktisch die meisten Fälle, in denen Daten irgendwie verwendet werden.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    „Verantwortlicher“ ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    1.2 Zusammenarbeit mit Auftragsverarbeitern und Dritten
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Sofern wir im Rahmen unserer Datenverarbeitung anderen Personen und Unternehmen Daten offenbaren, sie an diese übermitteln oder ihnen auf eine andere Art und Weise Zugriffsmöglichkeiten einräumen, geschieht dies nur auf Grundlage einer gesetzlichen Erlaubnis. Wenn wir Daten etwa an einen Versanddienstleister übertragen, dient dies der Abwicklung des Vertragsverhältnisses. Eine Datenübermittlung kann auch erfolgen, wenn hierzu eingewilligt wurde (Art. 6 Abs. 1 Buchst. a DSGVO), eine rechtliche Verpflichtung eine Übertragung vorsieht (Art. 6 Abs. 1 Buchst. c DSGVO) oder wir uns auf unsere berechtigten Interessen berufen können (Art. 6 Abs. 1 Buchst. f DSGVO).
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Teilweise bedienen wir uns anderer Unternehmen als Auftragsverarbeiter. Hierzu schließen wir „Auftragsverarbeitungsvereinbarungen“ auf Grundlage des Art. 28 DSGVO ab.
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    1.3 Löschung von Daten
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir löschen oder schränken die Verarbeitung von personenbezogene Daten nach Maßgabe der Art. 17 und 18 DSGVO ein. Gespeicherte Daten werden im Allgemeinen gelöscht, wenn sie für ihre Zweckbestimmung nicht mehr gebraucht werden, es sei denn, der Löschung stehen gesetzliche Aufbewahrungspflichten entgegen. Wir schränken die Datenverarbeitung ein, wenn die Daten nicht gelöscht werden können, weil sie etwa für andere und gesetzliche zulässige Zwecke genutzt werden sollen. D. h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z. B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    In Deutschland erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Soweit in unseren Datenschutzbestimmungen ausdrücklich mitgeteilt wird, wann Daten gelöscht werden, gilt selbstverständlich diese konkrete Aussage zum Löschungszeitpunkt.
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    1.4 Datensicherheit
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Alle Datentransfers zwischen Endgeräten und unseren Servern oder den Servern unserer Dienstleister erfolgt verschlüsselt. Wir nutzen dafür marktübliche Verschlüsselungstechniken, damit Datentransfers sicher sind. Wir folgen ferner dem Prinzip der Datensparsamkeit und versuchen nur personenbezogene Daten zu erfassen und zu speichern, die wir für die Erbringung unserer Leistungen brauchen.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    2. Technisch notwendige Datenverarbeitung
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Im Rahmen der Erbringung unserer Leistungen kommt es zu technisch notwendiger Datenverarbeitung hierunter fallen z.B. Server Logs, Firewall Logs, etc. zum Beispiel bei unserem Hosting Partner hotsplots GmbH. Im Rahmen dieser Logs werden grundlegende Verbindungsdaten gespeichert wie z.B.:
                </Typography>
                <Typography variant='body1' component='ul' className={classes.ul}>
                    <li>Zeitpunkt des Zugriffs</li>
                    <li>IP-Adresse des Endgeräts</li>
                    <li>Angefragte Ressource</li>
                    <li>Übertragene Datenmenge</li>
                    <li>Browser, Browsertyp und Version</li>
                    <li>Betriebssystem</li>
                    <li>Ggfs. zuvor besuchte Seiten</li>
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    3. Hosting (hotsplots GmbH)
                </Typography >
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir nutzen für das Hosting der Webseite die Dienstleitungen der hotsplots GmbH, Rotherstraße 22, 10245 Berlin. Dieses Unternehmen stellt uns Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen zur Verfügung. Die hotsplots GmbH verarbeitet in unserem Auftrag Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten unserer Nutzer und Interessenten. Dies geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Webseite gem. Art. 6 Abs. 1 Buchst. f DSGVO i.V.m. Art. 28 DSGVO (Auftragsverarbeitungsvertrag). Wir haben vertraglich festgelegt, dass unsere Daten ausschließlich auf Servern in der Europäischen Union verarbeitet werden.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    4. Kommunikation
                </Typography >
                <Typography variant='body1' component='p' className={classes.p}>
                    Wenn Nutzer mit uns per E-Mail direkt Kontakt aufnehmen oder sich zu anderen Themen an uns wenden, verarbeiten wir die dabei übermittelten Informationen. Dies geschieht auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 Buchst. f DSGVO) daran, auf an uns gerichtete Kommunikation angemessen reagieren zu können. Die Daten der Nutzer werden gelöscht, sobald die jeweilige Anfrage erledigt ist und wenn keine gesetzlichen Aufbewahrungspflichten bestehen, die eine weitere Verarbeitung erforderlich machen.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    5. Teilnahme an Gewinnspielen
                </Typography >
                <Typography variant='body1' component='p' className={classes.p}>
                    Über die Webseite ist die Teilnahme an unterschiedlichen Gewinnspielen möglich. Um an diesen Gewinnspielen teilzunehmen, ist es erforderlich, dass Vor- und Nachname, Telefonnummer sowie eine E-Mail-Adresse angegeben werden. Wenn die Art des Gewinnspiels dies erforderlich macht, werden im Einzelfall weitere Daten für die Teilnahme abgefragt. Wir verarbeiten diese Daten auf Basis Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO) und auf Grundlage unseres berechtigten Interesses (Art. 6 Abs. 1 Buchst. f DSGVO). Unser Interesse besteht darin, die Gewinnspiele transparent durchführen und die Gewinner angemessen benachrichtigen zu können. Diese Daten werden gelöscht, wenn das Gewinnspiel durchgeführt ist und keine gesetzlichen Aufbewahrungspflichten eine fortgesetzte Verarbeitung dieser Daten erforderlich machen.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    6. Teilnahme an Mitmach-Aktionen
                </Typography >
                <Typography variant='body1' component='p' className={classes.p}>
                    Über die Webseite ist auch eine Teilnahme an verschiedenen Mitmach-Aktionen möglich. Für die Teilnahme ist es ist erforderlich, dass Vor- und Nachname, Telefonnummer sowie eine E-Mail-Adresse angegeben werden. Wenn die Art der Mitmach-Aktion dies erforderlich macht, werden im Einzelfall weitere Daten für die Teilnahme abgefragt. Wir verarbeiten diese Daten auf Basis Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO). Diese Daten werden gelöscht, wenn die Mitmach-Aktion durchgeführt ist und keine gesetzlichen Aufbewahrungspflichten eine fortgesetzte Verarbeitung dieser Daten erforderlich machen.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    7. Analyse mit Hilfe von Auftragsverarbeiter Matomo
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir verwenden Matomo, ein Analysetool der InnoCraft Inc., 7 Waterloo Quay PO625, 6140 Wellington, New Zealand.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Matomo verwendet sog. „1st-Party-Cookies“ die direkt auf unserer Webseite gesetzt sind und deren Informationen direkt an unsere Server übertragen werden. Nähere Details zu diesen Cookies finden Sie unter: <a href="https://matomo.org/faq/general/faq_146/" target="_blank" rel="noopener noreferrer">https://matomo.org/faq/general/faq_146/</a>.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wenn Sie der Nutzung von Cookies zustimmen, werden folgende Cookies gesetzt:
                </Typography>
                <table className={classes.table}>
                    <thead>
                        <tr className={classes.tableHeaderRow}>
                            <Typography variant='body1' component='th' className={classes.tableHeader}>Cookie-Name</Typography>
                            <Typography variant='body1' component='th' className={classes.tableHeader}>Speicherdauer</Typography>
                            <Typography variant='body1' component='th' className={classes.tableHeader}>Cookie-Zweck</Typography>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={classes.tableRow}>
                            <Typography variant='body1' component='td' className={classes.tableData}>_pk_id</Typography>
                            <Typography variant='body1' component='td' className={classes.tableData}>13 Monate</Typography>
                            <Typography variant='body1' component='td' className={classes.tableDataText}>Von Matomo vergebene eindeutige Identifikationsnummer, zwecks Erkennung wiederkehrender Besucher der Website.</Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography variant='body1' component='td' className={classes.tableData}>_pk_ses</Typography>
                            <Typography variant='body1' component='td' className={classes.tableData}>30 Minuten</Typography>
                            <Typography variant='body1' component='td' className={classes.tableDataText}>Von Matomo gesetzter Cookie. Dient zur Erkennung, ob der Nutzer länger die Seite verlassen hat.</Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography variant='body1' component='td' className={classes.tableData}>mtm_consent</Typography>
                            <Typography variant='body1' component='td' className={classes.tableData}>unbegrenzt</Typography>
                            <Typography variant='body1' component='td' className={classes.tableDataText}>Speichert Ihre Einwilligung zum Tracking.</Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography variant='body1' component='td' className={classes.tableData}>mtm_consent_removed</Typography>
                            <Typography variant='body1' component='td' className={classes.tableData}>unbegrenzt</Typography>
                            <Typography variant='body1' component='td' className={classes.tableDataText}>Speichert Ihre Ablehnung des Trackings.</Typography>
                        </tr>
                    </tbody>
                </table>
                <Typography variant='body1' component='p' className={classes.p}>
                    Zweck der Datenverarbeitung ist die statistische Auswertung und Analyse der Leistung unserer Webangebotes und wie Besucher unsere Webseite nutzen. Dies erfolgt in pseudonymisierter Form. Der Einsatz von Matomo ermöglicht uns also die Webseitenoptimierung.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Alle Cookies die Informationen über die Nutzung der Webseite enthalten, werden nach spätestens 13 Monaten automatisch gelöscht.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir setzen dieses Analysewerkzeug auf Grundlage unserer berechtigten Interessen an einer effizienten und effektiven Optimierung unserer Webseite ein, Art. 6 Abs. 1 lit. f) DSGVO und auf Basis Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO). Sie können dieser Datenverarbeitung jederzeit widersprechen, indem Sie auf den nachfolgenden Button klicken. Dadurch werden alle gespeicherten Cookies gelöscht. Im Anschluss erhalten Sie wieder die Möglichkeit, der Verwendung von Cookies zuzustimmen oder diese abzulehnen.
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    Cookie-Entscheidung zurücksetzen
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Hier können Sie Ihre Entscheidung bezüglich der Verwendung von Cookies zurücksetzen. Dadurch werden alle gespeicherten Cookies gelöscht. Im Anschluss erhalten Sie wieder die Möglichkeit, der Verwendung von Cookies zuzustimmen oder diese abzulehnen.
                </Typography>
                <Button
                    variant='outlined'
                    classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                    }}
                    onClick={() => {
                        clearConsent();
                    }}
                >
                    Cookie-Entscheidung zurücksetzen
                </Button>
                <Typography variant='body1' component='p' className={classes.p}>
                    Weitere Hinweise zum Datenschutz bei Matomo finden Sie unter <a href="https://matomo.org/privacy-policy" target="_blank" rel="noopener noreferrer">https://matomo.org/privacy-policy</a>.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    8. Widerspruchsrecht.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Nutzer können der Verarbeitung ihrer personenbezogenen Daten jederzeit entsprechend den gesetzlichen Vorgaben widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen. Soweit wir in diesen Datenschutzbestimmungen eine Opt-Out-Möglichkeit zur Verfügung stellen, können Sie einfach auf diesem Wege das Widerspruchsrecht ausüben.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    9. Widerrufsrecht von Einwilligungen.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Eine erklärte Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Dazu reicht eine formlose Mitteilung per E-Mail an <a href="mailto:info@fahrgastfernsehen.city">info@fahrgastfernsehen.city</a> oder Sie nutzen eine von uns bereitgestellte Opt-Out-Möglichkeit, etwa in unserem Cookie-Tool. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    10. Betroffenenrechte
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4}>
                    10.1 Auskunft
                </Typography>
                < Typography variant='body1' component='p' className={classes.p} >
                    Nutzer haben das Recht, Auskunft darüber zu verlangen, ob und wie betreffende Daten verarbeitet werden, sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
                </Typography >
                <Typography variant='h6' component='h4' className={classes.h4}>
                    10.2 Vervollständigung und Berichtigung
                </Typography>
                < Typography variant='body1' component='p' className={classes.p} >
                    Nutzer haben entsprechend Art. 16 DSGVO das Recht, die Vervollständigung der sie betreffenden Daten oder die Berichtigung der sie betreffenden unrichtigen Daten zu verlangen.
                </Typography >
                <Typography variant='h6' component='h4' className={classes.h4}>
                    10.3 Löschung und Einschränkung
                </Typography>
                < Typography variant='body1' component='p' className={classes.p} >
                    Nutzer haben nach Maßgabe des Art. 17 DSGVO das Recht, zu verlangen, dass sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO, dass die Verarbeitung der Daten eingeschränkt wird.
                </Typography >
                <Typography variant='h6' component='h4' className={classes.h4}>
                    10.4 Datenportabilität
                </Typography>
                < Typography variant='body1' component='p' className={classes.p} >
                    Nach Art. 20 DSGVO haben Nutzer das Recht, Daten, die wir auf Grundlage ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sie oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Nutzer die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </Typography >
                <Typography variant='h6' component='h4' className={classes.h4}>
                    10.5 Beschwerderecht
                </Typography>
                < Typography variant='body1' component='p' className={classes.p} >
                    Wenn Nutzer eine unrechtmäßige Datenverarbeitung annehmen, steht es ihnen frei, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes Niedersachsen, in dem unser Unternehmen seinen Sitz hat.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    11. Änderung der Datenschutzbestimmungen
                </Typography >
                < Typography variant='body1' component='p' className={classes.p} >
                    Die Datenschutzbestimmungen informieren jeweils über die aktuelle Datenverarbeitung im Zusammenhang mit unserer Webseite. Wenn sich Änderungen bei unseren Diensten oder dieser Datenverarbeitung ergeben oder sich die Rechtslage ändert, müssen wir diese Datenschutzbestimmungen entsprechend anpassen. Dies gilt jedoch nur im Hinblick auf diese Informationen zur Datenverarbeitung. Sofern Einwilligungen erforderlich waren, werden wir hieran ohne das Einverständnis der jeweiligen Nutzern natürlich keine Änderungen vornehmen
                </Typography >
            </Box >
        );
    }
}

DataprotectionPage.propTypes = {
    userLang: PropTypes.string,
    documentTitle: PropTypes.string.isRequired,
    clearConsent: PropTypes.func.isRequired,
};

export default withRouter((props) => {
    const { userLang, location } = props;
    const classes = useStyles();
    const documentTitle = L.t('dataprotectionPageTitle', userLang) + ' - ' +
        L.t('appTitle', userLang);
    const { trackPageView, pushInstruction } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    const clearConsent = () => {
        // forget consent to clear matomo cookies, but setting a forget cookie.
        pushInstruction('forgetConsentGiven');
        CookieAccess.deleteMatomoCookie();
        window.location.assign(location.pathname + location.search);
    };
    return (
        <DataprotectionPage
            {...props}
            classes={classes}
            documentTitle={documentTitle}
            clearConsent={clearConsent}
        />
    );
});
