import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";

import Survey from "./survey/Survey";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: 8,
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    textOnlyCard: {
        borderRadius: 0,
        transform: 'translateZ(0)',
        outline: '1px solid #888888',
    },
    cardContent: {
        padding: 0,
    },
}));

class SurveyCard extends PureComponent {
    constructor(props) {
        super(props);
        const focused = false;
        this.state = { focused };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onBlur() {
        const focused = false;
        this.setState({ focused });
    }

    render() {
        const {
            classes, data, userLang, textOnly,
        } = this.props;
        const { focused } = this.state;
        return (
            <Card
                className={focused ? classes.focusedCard :
                    (textOnly ? classes.textOnlyCard : classes.card)}
                elevation={textOnly ? 0 : 11}
            >
                <CardContent className={classes.cardContent}>
                    <Survey userLang={userLang}
                            parentEntry={this.props.parentEntry}
                            location={this.props.location}
                            question={data.question}
                            answer1={data.answer1}
                            answer2={data.answer2}
                            answer3={data.answer3}
                            answer4={data.answer4}

                    >
                    </Survey>
                </CardContent>
            </Card >
        );
    }

}

SurveyCard.propTypes = {
    data: PropTypes.object.isRequired,
    parentEntry:PropTypes.object,
    userLang: PropTypes.string,
};


export default withRouter((props) => {
    const classes = useStyles();
    return <SurveyCard {...props} classes={classes} />;
});

