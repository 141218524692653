import React, {useState} from "react";
//import L from "../util/L10n";
import useResizeObserver from '@react-hook/resize-observer'
import TextUtil from "../../util/TextUtil";
import ButtonBase from "@material-ui/core/ButtonBase";
import MaybeLink from "../../util/MaybeLink";
import EntryUrl from "../../util/EntryUrl";
import "./styles.css"

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}


export default function Survey({
                                   /*classes,
                                   userLang,*/
                                   parentEntry,
                                   location,
                                   question,
                                   answer1,
                                   answer2,
                                   answer3,
                                   answer4,
                               }) {

    const [target, setTarget] = React.useState();
    const size = useSize(target);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [result, setResult] = useState(false);
    const [result1, setResult1] = useState(null);
    const [result2, setResult2] = useState(null);
    const [result3, setResult3] = useState(null);
    const [result4, setResult4] = useState(null);


    let myStyle = {
        backgroundImage:
            'url(' + process.env.PUBLIC_URL + '/images/background-survey.png)',
        backgroundSize: 'cover',
        color: 'rgb(255, 255, 255)',
        aspectRatio: '4/3',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    };

    function renderAnswer(id, text, selected, result) {

        let answerStyle = {
            position: "relative",
            flexGrow: 1,
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: '' + fontSize + 'px',
            color: 'rgb(255, 255, 255)',
            borderRadius: 8,
            borderColor: selected ? 'rgb(255, 255, 255)' : "transparent",
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: 'rgb(88, 136, 73)',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: 'DinforPBR'


        };

        let answerStyle1 = {
            position: "relative",
            flexGrow: 1,
            paddingTop: 0,
            paddingBottom: 1,
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: '' + fontSize + 'px',
            color: 'rgb(255, 255, 255)',
            borderRadius: 8,
            borderColor: selected ? 'rgb(255, 255, 255)' : "transparent",
            borderWidth: 1,
            borderStyle: 'solid',
            //backgroundColor: 'rgb(88, 136, 73)',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            fontFamily: 'DinforPBR',
        };


        let answer;
        if (result) {
            let rightRadius = 0;
            if (result > 98) {
                rightRadius = 8;
            }
            let inner;
            if(result) {
                inner = <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div>{text}</div><div>{result + "%"}</div>
                </div>

            } else {
                inner = <div>text</div>;
            }
            answer =
                <div style={{
                    position: 'relative', flexGrow: 1,
                    paddingBottom: 3,
                }}>
                    <div style={{
                        position: "absolute",
                        top: '0px',
                        height: '100%',
                        width: '100%',
                        borderRadius: 8,
                        backgroundColor: 'rgb(88, 136, 73)'
                    }}></div>
                    <div className="survey-bar" style={{
                        position: "absolute",
                        top: '0px',
                        height: '100%',
                        width: '' + result + '%',
                        //backgroundColor: 'rgba(100,100,100, 0.5)'
                        backgroundColor: 'rgb(139,172,128)',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTopRightRadius: rightRadius,
                        borderBottomRightRadius: rightRadius,


                    }}></div>

                    <div style={answerStyle1} onClick={function () {
                        if(!result) {
                            setSelectedAnswer(text);
                        }
                    }}>
                            {inner}
                    </div>


                </div>
        } else {
            answer = <div style={answerStyle} onClick={function () {
                setSelectedAnswer(text);
            }}>
                <div>
                    {text}
                </div>
            </div>
        }

        return (
            <div key={id} style={{
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 16,
                paddingRight: 16,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column"
            }}>
                {answer}
            </div>
        )
            ;
    }

    let height = 0;
    let fontSize = 0;
    if (size) {
        height = size.height;
        fontSize = ((height-80)  / 7)  * 0.55;
        console.log("size Survey", height)

    }

    let answers = []
    if (!TextUtil.isEmptyText(answer1)) answers.push(renderAnswer(1, answer1, selectedAnswer === answer1, result1))
    if (!TextUtil.isEmptyText(answer2)) answers.push(renderAnswer(2, answer2, selectedAnswer === answer2, result2))
    if (!TextUtil.isEmptyText(answer3)) answers.push(renderAnswer(3, answer3, selectedAnswer === answer3, result3))
    if (!TextUtil.isEmptyText(answer4)) answers.push(renderAnswer(4, answer4, selectedAnswer === answer4, result4))

    let url;
    if (parentEntry) {
        const pathname = EntryUrl.getPath(parentEntry);
        const state = {
            data: parentEntry,
            back: location.pathname,
        }
        url = {
            pathname: pathname,
            search: location.search,
            state: state,
        }
    }
    console.log(url);

    let title = <MaybeLink to={url}>
        <div style={{
            fontSize: '' + fontSize + 'px',
            fontWeight: 600,
            paddingTop: 16,
            paddingBottom: 12,
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: 'DinforPBR',
            lineHeight: 1.2,
        }}
        >

            <center>
                {question}
            </center>
        </div>
    </MaybeLink>;

    function getResult() {
        console.log("getResult")
        setResult(true);
        setResult1(20);
        setResult2(50);
        setResult3(99);
        setResult4(25);
    }


    let button;
    if(result) {
        button = <div key="comment"
        style={{
            paddingTop: "8px",
            paddingBottom: "8px",
            fontSize: '' + fontSize/2 + 'px',
            color: "rgb(139,172,128)"
        }}>
            <center>Nicht repräsentatives Stimmungsbild,<br></br>
                {"< 500 Teilnehmner"}</center>
        </div>

    } else if (selectedAnswer) {
        button = <ButtonBase style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: '' + fontSize + 'px',
            fontWeight: 600,
            color: 'rgb(51, 140, 21)',
            borderRadius: 8,
            backgroundColor: 'rgb(255, 255, 255)',
            width: '100%',

        }} onClick={getResult}>
            <center>
                abstimmen
            </center>
        </ButtonBase>
    } else {
        button = <div key="button" style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: '' + fontSize + 'px',
            fontWeight: 600,
            color: 'rgb(163, 163, 163)',
            borderRadius: 8,
            backgroundColor: 'rgb(224, 224, 224)',
            width: 'auto',
            fontFamily: 'DinforPBR',
        }}>
            <center>
                abstimmen
            </center>
        </div>

    }
    return (
        <div key={"servey_"} ref={setTarget} style={myStyle}>
            {title}
            <div key="question" style={{
                flexGrow: 1,
                height: "auto",
                display: "flex",
                flexDirection: "column"
            }}>
                {answers}
            </div>
            <div key="answers" style={{
                paddingTop: 8,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
            }}>
                {button}
            </div>
        </div>
    );
}
