export default class CookieAccess {
    static hasMatomoCookie() {
        const cookies = document.cookie;
        return !!cookies.match(/(^|;)\s*mtm_consent\s*=\s*([^;]+)/) ||
            !!cookies.match(/(^|;)\s*mtm_consent_removed\s*=\s*([^;]+)/);
    }

    static deleteMatomoCookie() {
        document.cookie = 'mtm_consent_removed=; ' +
            'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    static passwordCookieName(key) {
        return 'fgf_voucher_' + encodeURIComponent(key);
    }

    static setPasswordCookie(key, password) {
        const date = new Date(Date.now() + 86400000);
        document.cookie = this.passwordCookieName(key) + '=' +
            encodeURIComponent(password) + '; expires=' + date.toUTCString() +
            '; path=/;';
    }

    static deletePasswordCookie(key) {
        document.cookie = this.passwordCookieName(key) + '=; ' +
            'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    static getPasswordCookie(key) {
        const cookies = document.cookie;
        const name = this.passwordCookieName(key);
        const regexp = '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)';
        const result = cookies.match(new RegExp(regexp));
        if (result) {
            return result[2];
        } else {
            return null;
        }
    }
}
